import { Box, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import pdf from "../../../../assets/icons/file.png";
import lookBook1 from "../../../../assets/pdf/look-book1.pdf";
import lookBook2 from "../../../../assets/pdf/look-book2.pdf";
import lookBook3 from "../../../../assets/pdf/bookCover.pdf";
import aajivanSadasya from "../../../../assets/pdf/aajivanSadasya.pdf";
import ApprovalLetterforformCSR1 from "../../../../assets/pdf/ApprovalLetterCSR1.PDF";
import AUDITReport202122SVBPST from "../../../../assets/pdf/AUDITReport2021to22.pdf";
import AUDITReport202223SVBPST from "../../../../assets/pdf/AUDITReport2022to23.pdf";
import AUDITReport202324SVBPST from "../../../../assets/pdf/AuditReport2023to24.pdf";

import ITRAY2022to23 from "../../../../assets/pdf/ITRAY2022to23.pdf";
import ITRAY2023to24 from "../../../../assets/pdf/ITRAY2023to24.pdf";
import ITRAY2024to25 from "../../../../assets/pdf/ITRAY2024to25.pdf";

import ltVC4 from "../../../../assets/pdf/LT&VC4.pdf";
import PatelSamajInnerPage from "../../../../assets/pdf/PatelSamajInnerPage.pdf";
import PhotoFraming from "../../../../assets/pdf/PhotoFraming.pdf";
import RegistrationSVBPST from "../../../../assets/pdf/RegistrationSVBPST.pdf";

const Download = () => {
  const handleDownload = (item) => {
    const link = document.createElement("a");
    link.href = item.pdfName;
    link.setAttribute("download", `${item.pdfName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleView = (item) => {
    // Implement view functionality here
    // For simplicity, let's just open the PDF in a new tab
    window.open(item.pdfName, "_blank");
  };
  const data = [
    {
      pdfName: lookBook1,
      title: "Look Book 1",
    },
    {
      pdfName: lookBook2,
      title: "Look Book 2",
    },
    {
      pdfName: lookBook3,
      title: "Look Book 3",
    },
    {
      pdfName: aajivanSadasya,
      title: "Aajivan Sadasya",
    },
    {
      pdfName: ApprovalLetterforformCSR1,
      title: "Approval Letter CSR1",
    },
    {
      pdfName: AUDITReport202122SVBPST,
      title: "AUDIT Report 2021-22",
    },
    {
      pdfName: AUDITReport202223SVBPST,
      title: "AUDIT Report 2022-23",
    },
    {
      pdfName: AUDITReport202324SVBPST,
      title: "AUDIT Report 2023-24",
    },
    {
      pdfName: ITRAY2022to23,
      title: "ITRAY 2022-23",
    },
    {
      pdfName: ITRAY2023to24,
      title: "ITRAY 2023-24",
    },
    {
      pdfName: ITRAY2024to25,
      title: "ITRAY 2024-25",
    },

    {
      pdfName: ltVC4,
      title: "LT&VC4",
    },
    {
      pdfName: PatelSamajInnerPage,
      title: "Trust Inner Page",
    },
    {
      pdfName: PhotoFraming,
      title: "Photo Framing",
    },
    {
      pdfName: RegistrationSVBPST,
      title: "Registration SVBPST",
    },
  ];
  return (
    <Box className="w-100  p-3 d-flex gap-3 flex-wrap" minHeight="41vh">
      {data.map((elem) => {
        return (
          <>
            <Box
              maxWidth={300}
              minWidth={300}
              height={150}
              borderRadius={2}
              bgcolor="red"
              className="bg-pink-1"
              position="relative"
              p={2}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box
                  height={60}
                  width={60}
                  borderRadius={1}
                  bgcolor="white"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={pdf} height={50} width={50} />
                </Box>
                <Box>
                  <Typography color="white" fontWeight={600} fontSize={18}>
                    {elem.title}
                  </Typography>
                  <Typography color="white" fontSize={15}>
                    Pdf
                  </Typography>
                </Box>
              </Box>
              <Box
                mt={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  right: "5px",
                  bottom: "10px",
                }}
              >
                <IconButton onClick={() => handleView(elem)}>
                  <RemoveRedEyeIcon sx={{ color: "white" }} />
                </IconButton>
                <IconButton onClick={() => handleDownload(elem)}>
                  <DownloadForOfflineIcon sx={{ color: "white" }} />
                </IconButton>
              </Box>
            </Box>
          </>
        );
      })}
    </Box>
  );
};

export default Download;
