import * as React from "react";
import {
  ImageList,
  ImageListItem,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { imageList } from "../../../../assets/data";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function QuiltedImageList() {
  const [open, setOpen] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);

  const handleOpen = (index) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === imageList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? imageList.length - 1 : prevIndex - 1
    );
  };

  const currentImage = imageList[selectedImageIndex];

  return (
    <>
      <ImageList sx={{ width: 800, overflow: "hidden" }} cols={3}>
        {imageList.map((item, index) => (
          <ImageListItem
            key={item.img}
            sx={{
              m: 1,
              cursor: "pointer",
              p: 1,
            }}
            className="card bg-pink-1"
            onClick={() => handleOpen(index)}
          >
            <img
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{
                objectFit: "contain",
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {currentImage && (
            <img
              src={currentImage.img}
              alt={currentImage.title}
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                objectFit: "contain",
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handlePrev} aria-label="previous">
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={handleNext} aria-label="next">
            <ArrowForwardIcon />
          </IconButton>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
